import {Link} from 'gatsby';
import React from 'react';

const head = {
    fontWeight: '700',
    fontSize: '40px',
    color: '#5E72E4'
};

const Header = () => (
    <>
        <div style={{height: 10, background: '#5E72E4'}}/>
        <header
            style={{
                background: '#f5f9fb'
            }}
        >

            <div className='rcards'>
                <div className='rcard'>
                    <Link
                        to={'/'}
                        style={{textDecoration: 'none'}}
                    >
                        <div style={head}>
                            AirPlug
                        </div>
                    </Link>
                </div>

                <div className='rcard'>
                    <div className='flex-container'>
                        <Link to={'https://app.airplug.xyz/login'}>
                            <button
                                className='mybutton'
                            >Login</button>
                        </Link>
                    </div>
                </div>

            </div>
        </header>
    </>
);

export default Header;
